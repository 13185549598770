/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `'Citadel Script', cursive`,
  headingAlt: `'Cinzel Decorative', serif`,
  subHeading: `'Bodoni', serif`,
  body: `'Cormorant Upright', serif;`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
    color: 'mainColorText',
    textAlign: 'center',
  },
  subHeading: {
    fontFamily: 'subHeading',
    fontSize: 'subHeading',
    fontWeight: 'subHeading',
    fontStyle: 'var(--fontItalic-subHeading)',
    color: 'mainColorText',
    textAlign: 'center',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    color: 'mainColorText',
    textAlign: 'center',
  },
};

export const DEFAULT_PROPS = {
  button_transparent: {
    _active: { borderColor: 'transparent' },
    _hover: { borderColor: 'transparent' },
    _focus: { borderColor: 'transparent' },
  },
  button: {
    fontWeight: 'normal',
    bgColor: 'bgSecondary',
    color: 'secondaryColorText',
    fontFamily: 'body',
    padding: '4px 16px',
    height: 'unset',
    borderRadius: '4px',
    boxShadow: 'md',
    size: 'md',
    _active: { borderColor: 'transparent' },
    _hover: { borderColor: 'transparent' },
    _focus: { borderColor: 'transparent' },
  },
  option: {
    style: { color: 'black' },
  },
};
