/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////
export { DEFAULT_PROPS } from './default-props';

/////////////////////////////////////////////
// COLOR CONFIGURATION
/////////////////////////////////////////////
export const BG_PRIMARY = 'rgba(7,50,31,1)';
export const BG_SECONDARY = 'rgba(243,236,224,1)';
export const BG_ALTERNATIVE = 'rgba(101,0,11,1)';

export const TEXT_PRIMARY = 'rgba(243,236,224,1)';
export const TEXT_SECONDARY = 'rgba(101,0,11,1)';

export const NAVIGATION_BG = 'rgba(200,166,68,1)';
export const NAVIGATION_ICON = '#fff';

export const ASSETS_PRIMARY = '#c8a644';
