import { extendTheme } from '@chakra-ui/react';
import { FONT_FAMILY } from '@/constants/default-props';
import {
  ASSETS_PRIMARY,
  BG_PRIMARY,
  BG_SECONDARY,
  BG_ALTERNATIVE,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
} from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    headingAlt: FONT_FAMILY.headingAlt,
    subHeading: FONT_FAMILY.subHeading,
    body: FONT_FAMILY.body,

    // QR Code purposes, don't replace fonts below
    jost: `'Jost', sans-serif;`,
  },
  fontSizes: {
    heading: '52px',
    headingAlt: '32px',
    subHeading: '22px',
    body: '18px',
  },
  fontWeights: {
    heading: 400,
    headingAlt: 400,
    subHeading: 400,
    body: 400,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-subHeading': '',
        '--fontItalic-body': '',
      },
    },
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgAlternative: BG_ALTERNATIVE,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    mainAssetColor: ASSETS_PRIMARY,
    transparent: 'transparent',
    theme: {
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
    },
  },
};

export default extendTheme(theme);
